import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

import RealEstateProfessional from "../images/5.0 Forms/Real Estate Professional.svg";
import SoftwarePartner from "../images/5.0 Forms/Software Partner.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-900 pt-20 pb-32 md:pt-24 md:pb-60">
        <div className="container">
          <header className="mx-auto mb-12 text-center md:mb-16">
            <h1 className="text-white">Get Started With Realsynch</h1>
          </header>

          <div className="mx-auto grid max-w-[800px] gap-10 md:grid-cols-2">
            <Link
              to="/client-pricing/"
              className="rounded-xl bg-white p-8 text-center font-normal text-typography-body no-underline hover:text-typography-body hover:shadow-4xl"
            >
              <img
                src={RealEstateProfessional}
                alt="Real Estate Professional"
                className="mx-auto mb-3.5"
              />
              <div className="mb-1.5 text-lg font-bold text-typography-heading">
                Real Estate Professional
              </div>
              <p className="mb-0">
                I’m ready to reduce my team’s workload, lower costs, and have
                more time to focus on the tasks that will help grow the
                business.
              </p>
            </Link>

            <Link
              to="/partner-pricing/"
              className="rounded-xl bg-white p-8 text-center font-normal text-typography-body no-underline hover:text-typography-body hover:shadow-4xl"
            >
              <img
                src={SoftwarePartner}
                alt="Software Partner"
                className="mx-auto mb-3.5"
              />
              <div className="mb-1.5 text-lg font-bold text-typography-heading">
                Software Partner
              </div>
              <p className="mb-0">
                I’d like to learn more about how my software can integrate with
                Realsynch and provide more value for real estate teams.
              </p>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
